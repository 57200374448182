import React from "react";
import { LogoIcon } from "../../assets/ExportSvg";
import { Link } from "react-router-dom";
import { IoMoonSharp } from "react-icons/io5";
import { IoMdMenu } from "react-icons/io";
import { FaUsb } from "react-icons/fa";
import { useAppContext } from "../../context/AppContext";

function DashHeader() {
	const { setSelectConnect } = useAppContext();
	
	return (
		<nav className="fixed top-0 w-full py-3 px-4 lg:px-8 bg-color z-[10000]">
			<div className="w-full flex flex-row justify-between items-center">
				<div className="flex gap-10 items-center">
					<Link to="/">
						<LogoIcon />
					</Link>

					<ul className="hidden lg:flex gap-5 items-center">
						<li>
							<Link
								to="/dashboard"
								className="text-neutral-200 underline font-semibold">
								Dashboard
							</Link>
						</li>
						<li>
							<Link to="/dashboard" className="text-neutral-200 font-semibold">
								Tools
							</Link>
						</li>

						<li>
							<button
								
								className="bg-lime-400 px-3 py-2 font-bold">
								STAKE NOW
							</button>
						</li>
					</ul>
				</div>

				<div className="flex items-center gap-2">
					<button className="px-3 py-3 border border-neutral-200 border-opacity-35 justify-center items-center inline-flex">
						<IoMoonSharp className="text-neutral-200" />
					</button>
					<button className="px-3 py-3 border border-neutral-200 border-opacity-35 justify-center items-center inline-flex lg:hidden">
						<IoMdMenu className="text-neutral-200" />
					</button>
					<button
						onClick={() => setSelectConnect(true)}
						className="py-2 px-3 text-neutral-200 border border-neutral-200 border-opacity-35 justify-center items-center gap-2 inline-flex">
						<FaUsb className="text-neutral-200" />{" "}
						<span className="font-bold">CONNECT</span>
					</button>
				</div>
			</div>
		</nav>
	);
}

export default DashHeader;
