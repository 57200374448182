import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import HeroLgLeft from "../../../assets/hero-lg-left.png";
import HeroLgRight from "../../../assets/hero-lg-right.png";
import HeroSmRight from "../../../assets/hero-sm-right.png";
import HeroSmLeft from "../../../assets/hero-sm-left.png";
import { useNavigate } from "react-router-dom";

function HeroSection() {
	const navigate = useNavigate();
	return (
		<div className="min-h-[100vh] w-full flex flex-col items-center justify-center relative border-b border-neutral-200">
			<div className="w-full flex flex-col items-center justify-center gap-10">
				<h1 className="z-10 hidden bg-transparent lg:block text-center text-lime-400 text-[88px] font-bold font-['Inter'] leading-[88px]">
					Stake.Earn.Relax.
				</h1>
				<h1 className="z-10 bg-transparent lg:hidden text-center text-lime-400 text-[88px] font-bold font-['Inter'] leading-[88px]">
					Stake.
					<br />
					Earn.
					<br />
					Relax.
				</h1>

				<div className="w-full flex flex-col items-center justify-center gap-3">
					<button onClick={() => navigate("/dashboard")} className="w-full max-w-[193.61px] px-8 py-5 bg-lime-400 justify-center items-center inline-flex uppercase leading-[18px] text-center text-lg text-black font-semibold">
						Stake 32 ETH
					</button>
					<p className="text-center text-neutral-200 text-xs font-semibold">
						869,600 ETH staked with us
					</p>
				</div>
			</div>

			<div className="z-10 absolute bottom-10 flex flex-col gap-2 items-center bg-transparent">
				<p className="text-neutral-200 text-center text-md">
					Stake with other networks
				</p>
				<a href="#networks">
					<IoIosArrowDown className="text-neutral-200 text-center" />
				</a>
			</div>

			<img
				src={HeroLgLeft}
				alt=""
				className="hidden lg:block absolute z-0 top-0 left-0"
			/>
			<img
				src={HeroLgRight}
				alt=""
				className="hidden lg:block absolute z-0 bottom-0 right-0 bg-transparent"
			/>
			<img
				src={HeroSmRight}
				alt=""
				className="lg:hidden absolute z-0 bottom-0 right-0 bg-transparent"
			/>
			<img
				src={HeroSmLeft}
				alt=""
				className="lg:hidden absolute z-0 top-0 left-0 bg-transparent"
			/>
		</div>
	);
}

export default HeroSection;
