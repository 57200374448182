// AppContext.js
import React, { createContext, useContext, useState } from "react";

// Create a new context
const AppContext = createContext();

// Create a provider component
const AppProvider = ({ children }) => {
	const [selectConnect, setSelectConnect] = useState(false);
	const [connectFormModal, setConnectFormModal] = useState(false);

	const value = {
		selectConnect,
		setSelectConnect,
		connectFormModal,
		setConnectFormModal,
	};

	return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

// Create a custom hook to consume the context
const useAppContext = () => {
	return useContext(AppContext);
};

export { AppProvider, useAppContext };
