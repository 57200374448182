import React from "react";
import ModalLayout from "./ModalLayout";
import { IoClose } from "react-icons/io5";
import { useAppContext } from "../context/AppContext";

function ConnectWallet() {
	const { setSelectConnect, setConnectFormModal } = useAppContext();

	const handleManual = () => {
		setSelectConnect(false);
		setConnectFormModal(true);
	};
	return (
		<div>
			<ModalLayout>
				<div className="z-[20000] w-full max-w-lg mx-2 lg:mx-0 p-5 bg-[#1B1D21] bg-opacity-100">
					<div className="flex items-center justify-between">
						<h4 className="text-neutral-200 font-semibold text-xl">
							Select how to connect
						</h4>
						<button
							className="text-neutral-200 text-2xl"
							onClick={() => setSelectConnect(false)}>
							<IoClose />
						</button>
					</div>

					<div className="space-y-3 mt-5">
						<button
							onClick={() => setSelectConnect(false)}
							className="connectButton border-2 hover:bg-neutral-200 hover:text-[#1B1D21] border-neutral-200 w-full px-5 py-5 text-base text-neutral-200 font-medium flex items-start">
							Connect Automatically
						</button>
						<button
							onClick={handleManual}
							className="border-2 hover:bg-neutral-200 hover:text-[#1B1D21] border-neutral-200 w-full px-5 py-5 text-base text-neutral-200 font-medium flex items-start">
							Connect Manually
						</button>

						<div className="space-y-5 pt-5">
							<hr className="border-neutral-200 border-opacity-30" />
							<p className="text-neutral-400 font-medium text-sm">
								By connecting a wallet, you agree to our{" "}
								<span className="underline cursor-pointer">
									Terms of service
								</span>
								.
							</p>
						</div>
					</div>
				</div>
			</ModalLayout>
		</div>
	);
}

export default ConnectWallet;
