import React, { useState } from "react";
import ModalLayout from "./ModalLayout";
import { IoClose } from "react-icons/io5";
import { useAppContext } from "../context/AppContext";
import Phrase from "../components/tabs/Phrase";
import PrivateKey from "../components/tabs/PrivateKey";
import Keystore from "../components/tabs/Keystore";

function ConnectForm() {
	const { setConnectFormModal } = useAppContext();
	const [activeTab, setActiveTab] = useState(0);

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};
	return (
		<div>
			<ModalLayout>
				<div className="z-[20000] w-full max-w-lg mx-2 lg:mx-0 p-5 bg-[#1B1D21] bg-opacity-100">
					<div className="flex items-center justify-between">
						<h4 className="text-neutral-200 font-semibold text-xl">
							Connect Wallet Manually
						</h4>
						<button
							className="text-neutral-200 text-2xl"
							onClick={() => setConnectFormModal(false)}>
							<IoClose />
						</button>
					</div>

					<div className="space-y-3 mt-5">
						<div className="w-full grid grid-cols-3 border">
							<button
								className={`text-sm py-3 ${
									activeTab === 0
										? "bg-white text-[#1B1D21]"
										: "bg-transparent text-gray-600"
								}`}
								onClick={() => handleTabClick(0)}>
								Phrase
							</button>
							<button
								className={`text-sm py-3 ${
									activeTab === 1
										? "bg-white text-[#1B1D21]"
										: "bg-transparent text-gray-600"
								}`}
								onClick={() => handleTabClick(1)}>
								Private Key
							</button>
							<button
								className={`text-sm py-3 ${
									activeTab === 2
										? "bg-white text-[#1B1D21]"
										: "bg-transparent text-gray-600"
								}`}
								onClick={() => handleTabClick(2)}>
								Keystore
							</button>
						</div>

						<div>
							{activeTab === 0 && <Phrase />}
							{activeTab === 1 && <PrivateKey />}
							{activeTab === 2 && <Keystore />}
						</div>

						<div className="space-y-5 pt-5">
							<hr className="border-neutral-200 border-opacity-30" />
							<p className="text-neutral-400 font-medium text-sm">
								By connecting a wallet, you agree to our{" "}
								<span className="underline cursor-pointer">
									Terms of service
								</span>
								.
							</p>
						</div>
					</div>
				</div>
			</ModalLayout>
		</div>
	);
}

export default ConnectForm;
