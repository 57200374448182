import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "../screens/home/HomePage";
import Footer from "../components/footer/Footer";
import Dashboard from "../screens/dashboard/Dashboard";

function RouterPage() {
	return (
		<Router>
			<Routes>
				<Route exact path="/" element={<HomePage />} />
				<Route exact path="/dashboard" element={<Dashboard />} />
				<Route exact path="*" element={<HomePage />} />
			</Routes>
			<Footer />
		</Router>
	);
}

export default RouterPage;
