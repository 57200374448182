import React from "react";
import RouterPage from "./router/RouterPage";
import { useAppContext } from "./context/AppContext";
import ConnectWallet from "./modal/ConnectWallet";
import ConnectForm from "./modal/ConnectForm";

function App() {
	const { selectConnect, connectFormModal } = useAppContext();
	return (
		<div>
			<RouterPage />

			{selectConnect && <ConnectWallet />}
			{connectFormModal && <ConnectForm />}
		</div>
	);
}

export default App;
