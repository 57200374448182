import React from "react";

function ModalLayout({ children }) {
	return (
		<div className="fixed top-0 z-[10000] w-full h-screen bg-[#373738] bg-opacity-80 flex items-center justify-center">
			{children}
		</div>
	);
}

export default ModalLayout;
