import React from "react";

function TableSection() {
	return (
		<div className="space-y-2 lg:space-y-0 lg:flex text-sm">
			<div className="w-full border border-neutral-200 border-opacity-20">
				<div className="px-5 py-2 text-neutral-200">
					<p>Current balance</p>
					<p>—</p>
				</div>

				<div className="px-5 py-2 text-neutral-200 border-t border-neutral-200 border-opacity-20">
					<p>Effective balance</p>
					<p>—</p>
				</div>
				<div className="px-5 py-2 text-neutral-200 border-t border-neutral-200 border-opacity-20">
					<p>Pending protocol rewards</p>
					<p>—</p>
				</div>
			</div>

			<div className="w-full border border-neutral-200 border-opacity-20">
				<div className="px-5 py-2 text-neutral-200">
					<p>Annual rewards rate</p>
					<p>—</p>
				</div>

				<div className="px-5 py-2 text-neutral-200 border-t border-neutral-200 border-opacity-20">
					<p>Annual protocol rewards rate</p>
					<p>—</p>
				</div>
				<div className="px-5 py-2 text-neutral-200 border-t border-neutral-200 border-opacity-20">
					<p>Annual tip/mev rewards rate</p>
					<p>—</p>
				</div>
			</div>

			<div className="w-full border border-neutral-200 border-opacity-20">
				<div className="px-5 py-2 text-neutral-200">
					<p>Unclaimed tip/mev rewards</p>
					<p>—</p>
				</div>

				<div className="px-5 py-2 text-neutral-200 border-t border-neutral-200 border-opacity-20">
					<p>Claimed tip/mev rewards</p>
					<p>—</p>
				</div>
				<div className="px-5 py-2 text-neutral-200 border-t border-neutral-200 border-opacity-20">
					<p>Total tip/mev rewards</p>
					<p>—</p>
				</div>
			</div>
		</div>
	);
}

export default TableSection;
