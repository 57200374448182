import React from "react";
import Header from "../../components/header/Header";
import HeroSection from "./component/HeroSection";
import Unrivaled from "./component/Unrivaled";
import StakeSection from "./component/StakeSection";

function HomePage() {
	return (
		<div>
			<Header />
			<HeroSection />
			<Unrivaled />
			<StakeSection />
		</div>
	);
}

export default HomePage;
