import React from "react";
import {
	FaTwitter,
	FaTelegramPlane,
	FaYoutube,
	FaInstagram,
	FaLinkedinIn,
	FaRedditAlien,
} from "react-icons/fa";

function Footer() {
	return (
		<footer className="w-full py-5 px-4 lg:px-8 bg-color border-t border-neutral-200 border-opacity-30">
			<div className="w-full flex flex-col lg:flex-row items-center justify-between gap-10">
				<div className="flex flex-col lg:flex-row gap-5 lg:gap-10 text-neutral-200 items-center">
					<p>© 2024 stakefish</p>
					<a href="/" className="text-xs underline">Terms of service</a>
					<a href="/" className="text-xs underline">Privacy policy</a>
				</div>
				<div className="flex gap-3 text-neutral-200 items-center">
					<FaTwitter />
					<FaTelegramPlane />
					<FaYoutube />
					<FaInstagram />
					<FaLinkedinIn />
					<FaRedditAlien />
				</div>
			</div>
		</footer>
	);
}

export default Footer;
