import React from "react";
import DashHeader from "../../components/header/DashHeader";
import TableSection from "./components/TableSection";

function Dashboard() {
	return (
		<div>
			<DashHeader />

			<div className="my-20">
				<TableSection />
			</div>
		</div>
	);
}

export default Dashboard;
