import React from "react";
import { WandIcon } from "../../../assets/ExportSvg";
import DashIcon from "../../../assets/dashicon.png";
import FeeIcon from "../../../assets/feeicon.png";
import ZeroIcon from "../../../assets/zeroicon.png";
import DefiIcon from "../../../assets/defiicon.png";
import { FaArrowRight } from "react-icons/fa";
import Mobile from "../../../assets/mobiledevice.png";
import Desktop from "../../../assets/desktop.png";
import BgLeftLg from "../../../assets/bg-left-lg.png";
import BgRightLg from "../../../assets/bg-right-lg.png";
import BgLeftSm from "../../../assets/bg-left-sm.png";
import BgRightSm from "../../../assets/bg-right-sm.png";
import { useNavigate } from "react-router-dom";

function Unrivaled() {
	const navigate = useNavigate();
	return (
		<div className="relative border-b border-neutral-200 min-h-[100vh] w-full">
			<div className="mx-auto w-full max-w-[1200px]">
				<div className="space-y-12 w-full lg:max-w-xl px-4 lg:px-0 py-20">
					<WandIcon className="z-10 mx-auto lg:mx-0 w-[55px] h-[55px] lg:w-[86px] lg:h-[86px]" />
					<h2 className="z-10 text-neutral-200 text-center lg:text-start text-4xl lg:text-6xl font-bold leading-[43.20px] lg:leading-[67.20px]">
						Unrivaled Ethereum Staking Experience
					</h2>

					<img src={Mobile} alt="" className="flex mx-auto lg:hidden" />

					<div className="grid grid-cols-2 place-items-center lg:place-items-start gap-8">
						<div className="flex flex-col lg:flex-row items-center gap-5">
							<img src={DashIcon} alt="" className="w-[50%] lg:w-[60%]" />
							<p className="text-neutral-200 text-base lg:text-2xl font-semibold">
								Dashboard
							</p>
						</div>
						<div className="flex flex-col lg:flex-row items-center gap-5">
							<img src={DefiIcon} alt="" className="w-[50%] lg:w-[60%]" />
							<p className="text-neutral-200 text-base lg:text-2xl font-semibold">
								DeFi-ready
							</p>
						</div>
						<div className="flex flex-col lg:flex-row items-center gap-5">
							<img src={ZeroIcon} alt="" className="w-[50%] lg:w-[60%]" />
							<p className="text-neutral-200 text-base lg:text-2xl font-semibold">
								Zero protocol fee
							</p>
						</div>
						<div className="flex flex-col lg:flex-row items-center gap-5">
							<img src={FeeIcon} alt="" className="w-[50%] lg:w-[60%]" />
							<p className="text-neutral-200 text-base lg:text-2xl font-semibold">
								Fee/mev rewards
							</p>
						</div>
					</div>

					<button
						onClick={() => navigate("/dashboard")}
						className="mx-auto lg:mx-0 px-6 py-3.5 bg-neutral-200 flex items-center justify-center gap-2 ">
						LEARN MORE <FaArrowRight />
					</button>
				</div>
			</div>
			<img
				src={Desktop}
				alt=""
				className="absolute z-10 right-0 top-[20%] hidden lg:block"
			/>
			<img
				src={BgLeftLg}
				alt=""
				className="absolute z-[-1] left-0 top-[30%] hidden lg:block"
			/>
			<img
				src={BgRightLg}
				alt=""
				className="absolute z-[-1] right-0 top-0 hidden lg:block"
			/>
			<img
				src={BgLeftSm}
				alt=""
				className="absolute z-[-1] left-0 top-[50%] lg:hidden"
			/>
			<img
				src={BgRightSm}
				alt=""
				className="absolute z-[-1] right-0 top-0 lg:hidden"
			/>
		</div>
	);
}

export default Unrivaled;
