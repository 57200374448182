import React from "react";
import {
	Cosmos,
	EthIcon,
	Near,
	Polkadot,
	Polygon,
	Solana,
	Tezos,
} from "../../../assets/ExportSvg";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import BgImage from "../../../assets/bg-image.png";

function StakeSection() {
	const navigate = useNavigate();
	return (
		<div id="networks" className="relative w-full">
			<div className="mx-auto w-full max-w-[1200px]">
				<div className="space-y-12 w-full px-4 lg:px-0 py-20">
					<div className="flex flex-col-reverse lg:flex-row gap-5">
						<div className="space-y-2">
							<h2 className="z-10 text-neutral-200 text-[50.40px] text-center lg:text-start text-4xl lg:text-6xl font-bold leading-[57.96px] lg:leading-[67.20px]">
								Stake with stakefish.
								<br />{" "}
								<span className="text-neutral-200 text-[50.40px] font-normal leading-[57.96px]">
									Non-custodial. Secure.
								</span>
							</h2>
							<p className="text-zinc-400 text-md font-normal text-center lg:text-start">
								Secure networks and earn rewards with the leading validator in
								the ecosystem.
							</p>
						</div>
						<EthIcon className="flex mx-auto" />
					</div>

					<div className="grid lg:grid-cols-3 w-full gap-10">
						{data.map((item, index) => {
							return (
								<div
									onClick={() => navigate(`${item.url}`)}
									key={index}
									className="flex cursor-pointer items-center gap-5 border-t border-neutral-100 border-opacity-30 lg:border-0 pt-2 lg:pt-0">
									<div>{item.image}</div>
									<div className="h-full flex flex-col justify-between">
										<div className="space-y-1">
											<h4 className="text-neutral-200 text-[21.60px]">
												{item.title}
											</h4>
											<p className="text-neutral-200 text-xs">{item.desc}</p>
										</div>
										<a href={item.url} className="underline text-zinc-400">
											Learn more
										</a>
									</div>

									<div>
										<div className="lg:hidden w-[34px] h-[34px] border-2 border-white border-opacity-30 rounded-full flex items-center justify-center">
											<FaArrowRight className="text-white" />
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<img src={BgImage} alt="" className="absolute z-[-1] bottom-0" />
		</div>
	);
}

export default StakeSection;

const data = [
	{
		image: <Cosmos className="w-[52px] h-[52px] lg:w-[69px] lg:h-[69px]" />,
		title: "Cosmos",
		desc: "The internet of blockchains",
		url: "/dashboard",
	},
	{
		image: <Polkadot className="w-[52px] h-[52px] lg:w-[69px] lg:h-[69px]" />,
		title: "Polkadot",
		desc: "Sharded protocol enabling blockchain networks to operate together",
		url: "/dashboard",
	},
	{
		image: <Tezos className="w-[52px] h-[52px] lg:w-[69px] lg:h-[69px]" />,
		title: "Tezos",
		desc: "Platform for smart contracts and decentralized applications",
		url: "/dashboard",
	},
	{
		image: <Solana className="w-[52px] h-[52px] lg:w-[69px] lg:h-[69px]" />,
		title: "Solana",
		desc: "Fast, secure, and scalable blockchain network without sharding",
		url: "/dashboard",
	},
	{
		image: <Polygon className="w-[52px] h-[52px] lg:w-[69px] lg:h-[69px]" />,
		title: "Polygon",
		desc: "A protocol and framework for building and connecting Ethereum-compatible blockchain networks",
		url: "/dashboard",
	},
	{
		image: <Near className="w-[52px] h-[52px] lg:w-[69px] lg:h-[69px]" />,
		title: "NEAR",
		desc: "Sharded, developer-friendly, public Proof of Stake blockchain",
		url: "/dashboard",
	},
];
